import $ from 'jquery';
//import jQuery from 'jquery';
//import 'bootstrap/js/dist/util';
//import 'bootstrap/js/dist/dropdown';
import { Tooltip, Toast, Popover } from 'bootstrap';

import './scss/app.scss';

window.$ = $;

$(document).ready(function(){
	
	// Navigation levels
	$('.navbar .dropdown').hover(function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown(1);
	}, function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp(1);
	});

	$('.navbar .dropdown > a').click(function(){
		location.href = this.href;
	});

	// News filters
	function newsFilters(){
		$('.news-filter-names a').on('click', function(e){

			var hash = $(this).attr('href');
			hash = hash.replace(/^#/, '');

			$('.news-filter-names a').each(function(){
				$(this).removeClass('active');
			});	

			$(e.target).addClass('active');

			$('.news-item').each(function(index){
				if($(this).hasClass(hash)){
					$(this).addClass('reveal');
				}else{
					$(this).removeClass('reveal');
				}
			});

		});		
	}

	newsFilters();

	// Accordion items
	gsap.to(".accordion-header", {
		scrollTrigger: {
			trigger: ".accordion-header",
			start: "20% 80%",
		},
		opacity: 1, y: 0, duration: 0.8, stagger: 0.2}
	);



});